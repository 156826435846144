<template>
  <div
    class="c-page-top"
    :class="{
      'c-page-top-not-scroll': notScroll,
      'c-page-top-min': size === 'min',
      'c-page-top-train': isTrain
    }"
  >
    <button
      v-if="isBackButton && !isEdit"
      class="c-page-top-title"
      @click="pageBack()"
    >
      <text-with-icon :text="title" :iconName="iconName" size="title" />
    </button>
    <template v-else-if="isEdit">
      <button
        v-if="!editting && isBackButton"
        class="c-page-top-edit"
        @click="pageBack()"
      >
        <text-with-icon
          class="c-page-top-edit-main"
          :text="title"
          :iconName="iconName"
          size="title"
        />
        <top-edit-icon
          class="c-page-top-edit-icon"
          @click-edit="toggleEdit(), $emit('start-edit')"
        />
      </button>
      <div v-if="!editting && !isBackButton" class="top-title">
        <text-with-icon
          class="c-page-top-edit-main"
          :text="title"
          :iconName="iconName"
          size="title"
        />
        <top-edit-icon
          class="c-page-top-edit-icon"
          @click-edit="toggleEdit(), $emit('start-edit')"
        />
      </div>
      <top-edit-box
        v-else-if="editting"
        v-model="inputTitle"
        @finish-edit="toggleEdit(), $emit('finish-edit')"
      />
    </template>
    <button
      v-else-if="isButton"
      class="c-page-top-title"
      @click="$emit('click')"
    >
      <text-with-icon :text="title" :iconName="iconName" size="title" />
    </button>
    <div v-else class="c-page-top-title">
      <text-with-icon :text="title" :iconName="iconName" size="title" />
    </div>
    <slot />
  </div>
</template>

<script>
import textWithIcon from '@/components/molecules/text-with-icon'
import TopEditIcon from '@/components/atoms/top-edit-icon.vue'
import TopEditBox from '@/components/atoms/top-edit-box.vue'

export default {
  components: {
    textWithIcon,
    TopEditIcon,
    TopEditBox
  },
  data() {
    return {
      editting: false
    }
  },
  props: {
    /** 画面全体のスクロールがない場合はtrue */
    notScroll: Boolean,
    /** ページのタイトル */
    title: String,
    /** ページのタイトル横のアイコンの名前 */
    iconName: String,
    /** ページのタイトル押下で一つ前の画面に戻る場合 */
    isBackButton: Boolean,
    /** 編集ボタンの表示 */
    isEdit: Boolean,
    /** タイトル押下でclickをemitするようにする */
    isButton: Boolean,
    /** 領域が細い場合（説明文がないときとか）はminを渡す */
    size: String,
    /** 編集時にv-modelで渡される値 */
    value: String,
    /** 学習中画面で背景色を変更 */
    isTrain: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    pageBack() {
      this.$router.go(-1)
    },
    toggleEdit() {
      this.editting = !this.editting
    }
  },
  computed: {
    inputTitle: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.c-page-top {
  position: sticky;
  top: adjustVH($headerTabHeight);
  padding: $space-medium 0 $space-small;
  background: $background-sub;
  box-shadow: 0 0 $space-base $space-base rgb(248, 248, 250);
  z-index: $z-page-top;
  &-not-scroll {
    position: inherit;
    box-shadow: none;
  }
  &-title {
    max-width: calc(
      100vw - #{adjustVW(240) + $space-medium * 2 + adjustVW(32) + $space-base}
    ); // 100 - サイドバー - 左右のマージン - ヘルプアイコン
    margin: 0 0 $space-medium;
  }
  &-min {
    box-shadow: inherit;
    > .c-page-top-title {
      margin: 0;
    }
  }
  &-edit {
    display: flex;
    align-items: flex-end;
    max-width: adjustVW(960);
    &-main {
      margin: 0 $space-base 0 0;
    }
    &-icon {
      flex-shrink: 0;
    }
  }
  > button {
    &:hover {
      opacity: 1;
    }
  }
  &-train {
    background-color: $bg-train;
  }
}
</style>
