<template>
  <transition-group name="popup" class="popup-array">
    <popup
      v-for="(p, index) in showPopup"
      :key="p"
      show-popup
      v-bind="popupDefs[p] || {}"
      :style="{ position: 'fixed', 'z-index': index }"
      :noClose="noClose"
      @close-modal="closeModal(p, index)"
      @popup-inner-click="$emit('popup-inner-click')"
    >
      <slot :name="p" />
    </popup>
  </transition-group>
</template>

<script>
import Popup from './popup.vue'

export default {
  components: {
    Popup
  },
  model: { prop: 'showPopup', event: 'change' },
  props: {
    showPopup: Array,
    popupDefs: { type: Object, default: () => {} },
    noClose: { type: Boolean, default: false }
  },
  methods: {
    closeModal(name, index) {
      this.$emit('close-modal', name)
      const afterArray = this.showPopup.slice()
      afterArray.splice(index, 1)
      this.$emit('change', afterArray)
    }
  }
}
</script>
<style lang="scss" scoped>
.popup-enter-active,
.popup-leave-active {
  transition: opacity 0.5s;
}
.popup-enter,
.popup-leave-to {
  opacity: 0;
}
.popup-array {
  position: fixed;
  z-index: $z-popup;
}
</style>
