var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"task-window"},[_c('transition-toggle-contents',[(_vm.deletingTask)?_c('div',{staticClass:"task-loading"},[_c('loading-icon')],1):(_vm.checkAllLength)?_c('div',{key:"none",staticClass:"task-no-item"},[_c('transition-toggle-contents',[(!_vm.waitGetTasks)?_c('images',{staticClass:"task-no-item-img",attrs:{"imageName":"noItem"}}):_vm._e()],1),_c('div',{staticClass:"task-no-item-text"},[(_vm.waitGetTasks)?_c('loading-icon',{staticClass:"task-title-icon",attrs:{"size":"small"}}):_vm._e(),_c('texts',{staticClass:"task-no-item-content",attrs:{"size":"small","text":_vm.statusText,"color":"gray"}})],1)],1):_c('div',{key:"tasks",staticClass:"task-wrap"},[(_vm.pendingList.training && _vm.pendingList.training.length > 0)?_c('div',{staticClass:"task-body"},[_c('div',{staticClass:"task-title"},[_c('loading-icon',{staticClass:"task-title-icon",attrs:{"size":"small"}}),_c('texts',{attrs:{"text":_vm.$t('common.task.pending.title', {
                type: _vm.$t('common.task.' + _vm.type)
              }),"isBold":""}})],1),_c('tasks-list',{attrs:{"type":_vm.type,"items":_vm.pendingList.training,"showStop":"","showedStopTaskPopup":_vm.showedStopTaskPopup},on:{"link-to":_vm.linkTo,"stop":_vm.stop}})],1):_vm._e(),(_vm.pendingList.optimization && _vm.pendingList.optimization.length > 0)?_c('div',{staticClass:"task-body"},[_c('div',{staticClass:"task-title"},[_c('loading-icon',{staticClass:"task-title-icon",attrs:{"size":"small"}}),_c('texts',{attrs:{"text":_vm.$t('common.task.pending.titleOptimization'),"isBold":""}})],1),_c('tasks-list',{attrs:{"type":_vm.type,"items":_vm.pendingList.optimization,"showStop":"","showedStopTaskPopup":_vm.showedStopTaskPopup,"isOptimization":""},on:{"link-to":_vm.linkTo,"stop":_vm.stop}})],1):_vm._e(),(_vm.runningList.training && _vm.runningList.training.length > 0)?_c('div',{staticClass:"task-body"},[_c('div',{staticClass:"task-title"},[_c('loading-icon',{staticClass:"task-title-icon",attrs:{"size":"small"}}),_c('texts',{attrs:{"text":_vm.$t('common.task.running.title', {
                type: _vm.$t('common.task.' + _vm.type)
              }),"isBold":""}})],1),_c('tasks-list',{attrs:{"type":_vm.type,"items":_vm.runningList.training,"showStop":"","showedStopTaskPopup":_vm.showedStopTaskPopup},on:{"link-to":_vm.linkTo,"stop":_vm.stop}})],1):_vm._e(),(_vm.runningList.optimization && _vm.runningList.optimization.length > 0)?_c('div',{staticClass:"task-body"},[_c('div',{staticClass:"task-title"},[_c('loading-icon',{staticClass:"task-title-icon",attrs:{"size":"small"}}),_c('texts',{attrs:{"text":_vm.$t('common.task.running.titleOptimization'),"isBold":""}})],1),_c('tasks-list',{attrs:{"type":_vm.type,"items":_vm.runningList.optimization,"showStop":"","showedStopTaskPopup":_vm.showedStopTaskPopup,"isOptimization":""},on:{"link-to":_vm.linkTo,"stop":_vm.stop}})],1):_vm._e(),(_vm.finishedList.training && _vm.finishedList.training.length > 0)?_c('div',{staticClass:"task-body"},[_c('div',{staticClass:"task-title"},[_c('icons',{staticClass:"task-title-icon",attrs:{"iconName":_vm.iconType,"color":"emphasis"}}),_c('texts',{attrs:{"text":_vm.$t('common.task.finished.title', {
                type: _vm.$t('common.task.' + _vm.type)
              }),"isBold":""}})],1),_c('tasks-list',{attrs:{"type":_vm.type,"items":_vm.finishedList.training},on:{"link-to":_vm.linkTo,"stop":_vm.stop}})],1):_vm._e(),(
          _vm.finishedList.optimization && _vm.finishedList.optimization.length > 0
        )?_c('div',{staticClass:"task-body"},[_c('div',{staticClass:"task-title task-title-optimization"},[_c('div',{staticClass:"task-title-inner"},[_c('icons',{staticClass:"task-title-icon",attrs:{"iconName":"optimization","color":"emphasis"}}),_c('texts',{attrs:{"text":_vm.$t('common.task.finished.titleOptimization'),"isBold":""}})],1),_c('icons',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('common.task.finished.failedOptimizationTips')),expression:"$t('common.task.finished.failedOptimizationTips')"}],staticClass:"task-delete",attrs:{"iconName":"delete","isButton":"","size":22,"color":"caution"},on:{"icon-click":_vm.deleteOptimizationTasks}})],1),_c('tasks-list',{attrs:{"type":_vm.type,"items":_vm.finishedList.optimization,"isOptimization":"","isFailedOptimization":""},on:{"link-to":_vm.linkToAndDelete,"stop":_vm.stop}})],1):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }