<template>
  <div class="task-list">
    <div
      v-for="item in sortedItems"
      :key="item.jobId"
      :row="3"
      class="task-item"
    >
      <div class="task-item-inner">
        <div class="task-item-content">
          <text-with-title
            class="task-item-content-project"
            :title="$t('common.task.projectTitle')"
            :text="item.projectName"
            isBold
          />
          <text-with-title
            class="task-item-content-time"
            :title="$t('common.task.startedAt')"
          >
            <a-time :value="item.startedAt" withTime />
          </text-with-title>
        </div>
        <div class="task-item-button-wrap">
          <button-main
            v-if="!isOptimization && !isFailedOptimization"
            class="task-item-button"
            size="thin"
            :text="$t('common.showDetail')"
            type="green"
            fontSize="15"
            @click="linkTo(item)"
          />
          <button-main
            v-if="isFailedOptimization"
            class="task-item-button"
            size="thin"
            :text="$t('common.button.reStart')"
            type="emphasis"
            fontSize="15"
            @click="linkTo(item)"
          />
          <button-main
            v-if="
              showStop &&
                !isOptimization &&
                !isFailedOptimization &&
                type === 'training'
            "
            class="task-item-button"
            size="thin"
            :text="$t('training.popup.progress.stopButton', { type: typeText })"
            :type="showedStopTaskPopup ? 'disabled' : 'caution'"
            fontSize="15"
            @click="showedStopTaskPopup ? '' : stop(item)"
          />
        </div>
        <div
          v-if="isOptimization || isFailedOptimization"
          class="task-item-optimization"
          :class="{
            'task-item-optimization-failed': isFailedOptimization
          }"
        >
          <texts
            class="task-item-optimization-text"
            :text="optimizationText"
            size="min"
            color="gray"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main'
import textWithTitle from '@/components/molecules/text-with-title'

export default {
  components: {
    buttonMain,
    textWithTitle
  },
  props: {
    type: String,
    items: Array,
    showStop: Boolean,
    showedStopTaskPopup: Boolean,
    isOptimization: Boolean,
    isFailedOptimization: Boolean
  },
  computed: {
    optimizationText() {
      if (!this.isFailedOptimization) {
        return this.$t('common.task.runningOptimizationInfo')
      } else {
        return this.$t('common.task.failedOptimizationDesc')
      }
    },
    typeText() {
      if (!this.isOptimization) {
        return this.$t('common.task.' + this.type)
      } else {
        return this.$t('training.message.type.optimization')
      }
    },
    sortedItems() {
      if (!this.items || this.items.length === 0) return []
      const target = this.items
      target.sort((x, y) => (x.startedAt > y.startedAt ? -1 : 1))
      return target
    }
  },
  methods: {
    linkTo(item) {
      this.$emit('link-to', { item: item, type: this.type })
    },
    stop(item) {
      this.$emit('stop', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.task {
  &-list {
    height: 100%;
    @include scrollbar;
  }
  &-item {
    margin-bottom: $space-small;
    &:last-of-type {
      margin: 0;
    }
    &-inner {
      width: 100%;
      padding: adjustVW(14) $space-small adjustVW(14);
      background: $background-sub;
      border-radius: adjustVW(8);
    }
    &-content {
      overflow: hidden;
      display: flex;
      align-items: flex-start;
      grid-column-gap: adjustVW(18);
      margin-bottom: $space-sub;
      &:last-of-type {
        margin-bottom: 0;
      }
      &-project {
        overflow: hidden;
        width: 100%;
      }
      &-time {
        width: fit-content;
        justify-self: flex-end;
      }
    }
    &-button {
      max-width: 100%;
      padding: adjustVW(5) adjustVW(18) adjustVW(3.5);
      border-radius: adjustVW(8);
      + .c-button {
        margin: 0;
      }
      &-wrap {
        display: flex;
        grid-column-gap: $space-sub;
      }
    }
    &-optimization {
      &-text {
        white-space: pre-line;
      }
      &-failed {
        margin-top: $space-small;
      }
    }
  }
}
</style>
