<template>
  <div
    class="c-text-description"
    :class="{ 'c-text-description-gray': isGray }"
    :style="{ '--maxLine': line }"
  >
    {{ text }}
  </div>
</template>

<script>
export default {
  props: {
    /** 表示する文章 */
    text: String,
    /** 制限する行数 */
    line: {
      type: [Number, String],
      require: false,
      default: 3
    },
    /** グレーで表示 */
    isGray: Boolean
  }
}
</script>

<style lang="scss" scoped>
.c-text-description {
  --maxLine: 3;
  overflow: hidden;
  display: -webkit-box;
  line-height: $line-height-base;
  color: $text-main;
  font-size: $text-base;
  word-break: break-all;
  white-space: pre-line;
  -webkit-line-clamp: var(--maxLine);
  -webkit-box-orient: vertical;
  box-orient: vertical;
  &-gray {
    color: $text-sub;
    font-size: 1.45rem;
  }
}
</style>
