<template>
  <div class="task-window">
    <transition-toggle-contents>
      <div v-if="deletingTask" class="task-loading">
        <loading-icon />
      </div>
      <div v-else-if="checkAllLength" key="none" class="task-no-item">
        <transition-toggle-contents>
          <images
            v-if="!waitGetTasks"
            class="task-no-item-img"
            imageName="noItem"
          />
        </transition-toggle-contents>
        <div class="task-no-item-text">
          <loading-icon
            v-if="waitGetTasks"
            class="task-title-icon"
            size="small"
          />
          <texts
            class="task-no-item-content"
            size="small"
            :text="statusText"
            color="gray"
          />
        </div>
      </div>
      <div v-else key="tasks" class="task-wrap">
        <div
          v-if="pendingList.training && pendingList.training.length > 0"
          class="task-body"
        >
          <div class="task-title">
            <loading-icon class="task-title-icon" size="small" />
            <texts
              :text="
                $t('common.task.pending.title', {
                  type: $t('common.task.' + type)
                })
              "
              isBold
            />
          </div>
          <tasks-list
            :type="type"
            :items="pendingList.training"
            showStop
            :showedStopTaskPopup="showedStopTaskPopup"
            @link-to="linkTo"
            @stop="stop"
          />
        </div>
        <div
          v-if="pendingList.optimization && pendingList.optimization.length > 0"
          class="task-body"
        >
          <div class="task-title">
            <loading-icon class="task-title-icon" size="small" />
            <texts :text="$t('common.task.pending.titleOptimization')" isBold />
          </div>
          <tasks-list
            :type="type"
            :items="pendingList.optimization"
            showStop
            :showedStopTaskPopup="showedStopTaskPopup"
            isOptimization
            @link-to="linkTo"
            @stop="stop"
          />
        </div>
        <div
          v-if="runningList.training && runningList.training.length > 0"
          class="task-body"
        >
          <div class="task-title">
            <loading-icon class="task-title-icon" size="small" />
            <texts
              :text="
                $t('common.task.running.title', {
                  type: $t('common.task.' + type)
                })
              "
              isBold
            />
          </div>
          <tasks-list
            :type="type"
            :items="runningList.training"
            showStop
            :showedStopTaskPopup="showedStopTaskPopup"
            @link-to="linkTo"
            @stop="stop"
          />
        </div>
        <div
          v-if="runningList.optimization && runningList.optimization.length > 0"
          class="task-body"
        >
          <div class="task-title">
            <loading-icon class="task-title-icon" size="small" />
            <texts :text="$t('common.task.running.titleOptimization')" isBold />
          </div>
          <tasks-list
            :type="type"
            :items="runningList.optimization"
            showStop
            :showedStopTaskPopup="showedStopTaskPopup"
            isOptimization
            @link-to="linkTo"
            @stop="stop"
          />
        </div>
        <div
          v-if="finishedList.training && finishedList.training.length > 0"
          class="task-body"
        >
          <div class="task-title">
            <icons
              class="task-title-icon"
              :iconName="iconType"
              color="emphasis"
            />
            <texts
              :text="
                $t('common.task.finished.title', {
                  type: $t('common.task.' + type)
                })
              "
              isBold
            />
          </div>
          <tasks-list
            :type="type"
            :items="finishedList.training"
            @link-to="linkTo"
            @stop="stop"
          />
        </div>
        <div
          v-if="
            finishedList.optimization && finishedList.optimization.length > 0
          "
          class="task-body"
        >
          <div class="task-title task-title-optimization">
            <div class="task-title-inner">
              <icons
                class="task-title-icon"
                iconName="optimization"
                color="emphasis"
              />
              <texts
                :text="$t('common.task.finished.titleOptimization')"
                isBold
              />
            </div>
            <icons
              v-tooltip="$t('common.task.finished.failedOptimizationTips')"
              class="task-delete"
              iconName="delete"
              isButton
              :size="22"
              color="caution"
              @icon-click="deleteOptimizationTasks"
            />
          </div>
          <tasks-list
            :type="type"
            :items="finishedList.optimization"
            isOptimization
            isFailedOptimization
            @link-to="linkToAndDelete"
            @stop="stop"
          />
        </div>
      </div>
    </transition-toggle-contents>
  </div>
</template>

<script>
import icons from '@/components/atoms/icon'
import images from '@/components/atoms/images'
import loadingIcon from '@/components/atoms/loading-icon'
import texts from '@/components/atoms/text'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import tasksList from './tasks-list.vue'

export default {
  name: 'TaskWindow',
  components: {
    icons,
    images,
    loadingIcon,
    texts,
    transitionToggleContents,
    tasksList
  },
  props: {
    type: String,
    pending: Array,
    running: Array,
    finished: Array,
    waitGetTasks: Boolean,
    deletingTask: Boolean,
    showedPopup: Array
  },
  data() {
    return {}
  },
  computed: {
    statusText() {
      if (this.waitGetTasks) {
        return this.$t('common.task.waitGetInfo', {
          type: this.$t('common.task.' + this.type)
        })
      } else {
        return this.$t('common.task.noRunningTask', {
          type: this.$t('common.task.' + this.type)
        })
      }
    },
    pendingList() {
      if (!this.pending || this.pending.length === 0) {
        return {
          training: [],
          optimization: []
        }
      }
      const training = this.pending.filter((item) => {
        return !item?.isDifferentOptimization
      })
      const optimization = this.pending.filter((item) => {
        return item?.isDifferentOptimization
      })
      return {
        training: training ?? [],
        optimization: optimization ?? []
      }
    },
    runningList() {
      if (!this.running || this.running.length === 0)
        return {
          training: [],
          optimization: []
        }
      const training = this.running.filter((item) => {
        return !item?.isDifferentOptimization
      })
      const optimization = this.running.filter((item) => {
        return item?.isDifferentOptimization
      })
      return {
        training: training ?? [],
        optimization: optimization ?? []
      }
    },
    finishedList() {
      if (!this.finished || this.finished.length === 0) {
        return {
          training: [],
          optimization: []
        }
      }
      const training = this.finished.filter((item) => {
        return !item?.isDifferentOptimization
      })
      /**
       *  TODO 失敗した最適化だけに絞って表示しているが、これは２回目の最適化画面には戻れないため。
       *  二回目の最適化に戻れるようにする以下の対応が完了したタイミングで、
       *  学習時と同様に、２回目の最適化画面でエラーを表示できるようにする
       *  https://trello.com/c/OTKIAPzX
       */
      const optimization = this.finished.filter((item) => {
        return item?.isDifferentOptimization && item?.status === 'FAILED'
      })
      return {
        training: training ?? [],
        optimization: optimization ?? []
      }
    },
    checkAllLength() {
      if (!this.pending || !this.running || !this.finishedList) return false
      return (
        this.pending.length === 0 &&
        this.running.length === 0 &&
        this.finishedList.training.length === 0 &&
        this.finishedList.optimization.length === 0
      )
    },
    iconType() {
      if (this.type === 'training') {
        return 'trainedAi'
      } else if (this.type === 'inference') {
        return 'inference'
      } else {
        return ''
      }
    },
    showedStopTaskPopup() {
      if (!this.showedPopup || this.showedPopup.length === 0) return false
      return this.showedPopup.includes('stopTask')
    }
  },
  methods: {
    linkTo({ item, type }) {
      if (type === 'training') {
        this.$gtmDataLayer.sendEvent('進行中の学習に戻るボタンクリック')
      } else if (type === 'inference') {
        this.$gtmDataLayer.sendEvent('進行中の推論に戻るボタンクリック')
      }
      if (!item?.isDifferentOptimization) {
        const query = {}
        const params = {}
        let name
        if (type === 'training') {
          name = 'trainingTask'
          if (item?.isOptimization) {
            query.isOptimization = true
          }
          params.taskId = item.jobId
        } else if (type === 'inference') {
          name = 'inferenceProjectTask'
          params.inferenceId = item.parameters.inference_id
        }
        params.projectId = item.projectId
        this.$router.push({
          name: name,
          params: params,
          query: { ...query }
        })
      }
    },
    linkToAndDelete(item) {
      this.$emit('link-to-optimization-failed', item)
    },
    stop(item) {
      this.$emit('stop-task', item)
    },
    deleteOptimizationTasks() {
      this.$emit('delete-optimization-failed', this.finishedList.optimization)
    }
  }
}
</script>
<style scoped lang="scss">
.task {
  &-window {
    height: 100%;
    padding: adjustVW(24) + $space-small * 2 $space-sub $space-medium
      $space-medium;
  }
  &-wrap {
    overflow-y: auto;
    height: 100%;
    padding-right: $space-sub;
    @include scrollbar;
  }
  &-title {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    grid-column-gap: $space-sub;
    padding-bottom: $space-small;
    background: #fff;
    box-shadow: 0 0 4px 0px rgba(255, 255, 255, 0.8);
    z-index: 1;
    &-optimization {
      justify-content: space-between;
    }
    &-inner {
      display: flex;
      align-items: center;
      grid-column-gap: $space-sub;
    }
  }
  &-delete {
    margin-top: adjustVW(-6);
  }
  &-body {
    display: flex;
    flex-direction: column;
    padding-bottom: $space-medium;
    margin-bottom: $space-medium;
    border-bottom: $border-sub;
    &:last-of-type {
      padding: 0;
      margin: 0;
      border: none;
    }
  }
  &-no-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-row-gap: $space-medium;
    height: 100%;
    &-text {
      display: flex;
      align-items: center;
      grid-column-gap: $space-sub;
    }
    &-img {
      width: adjustVW(280);
    }
    &-content {
      text-align: center;
      white-space: pre-line;
    }
  }
}
</style>
