<template>
  <div class="top-edit-area">
    <div
      class="top-edit-box"
      :class="[
        size ? 'top-edit-box-' + size : '',
        {
          'top-edit-box-accept': accept,
          'top-edit-box-error': error
        }
      ]"
    >
      <div v-if="!isTextArea" class="top-edit-box-input">
        <input
          v-model="inputValue"
          class="top-edit-box-input-inner"
          @blur="onBlur"
        >
      </div>
      <textarea
        v-else
        v-model="inputValue"
        class="top-edit-box-input"
        type="textarea"
        @blur="onBlur"
      />
      <texts class="top-edit-box-input-dummy" :text="inputValue" :size="size" />
      <icons
        v-if="error"
        class="top-edit-box-error-icon"
        color="caution"
        iconName="error"
        size="small"
      />
      <icons
        v-else-if="accept"
        class="top-edit-box-accept-icon"
        color="emphasis"
        iconName="check"
        size="small"
      />
      <popup-balloon-name-suggest
        v-if="error"
        :showPopup="error"
        :error="error"
        :nameValidationSuggests="nameValidationSuggests"
        @select-suggest="selectSuggest"
      />
    </div>
    <button
      v-if="!isTextArea"
      class="top-edit-area-button"
      :disabled="disabledSubmit || error"
      :class="{ 'top-edit-area-button-disabled': disabledSubmit || error }"
      @click.stop="$emit('click-finish-edit'), $emit('finish-edit')"
    >
      <text-with-icon
        :text="$t('common.finishEditting')"
        iconName="save"
        size="small"
        :color="error ? 'gray' : 'default'"
      />
    </button>
  </div>
</template>

<script>
import Icons from '@/components/atoms/icon.vue'
import Texts from '@/components/atoms/text'
import TextWithIcon from '@/components/molecules/text-with-icon.vue'
import popupBalloonNameSuggest from '@/components/molecules/popup-balloon-name-suggest.vue'

export default {
  components: {
    Icons,
    Texts,
    TextWithIcon,
    popupBalloonNameSuggest
  },
  props: {
    /** タイトルの重複などのエラーが発生していない状態 */
    accept: {
      type: Boolean,
      default: null
    },
    /** タイトルの重複などのエラー */
    error: {
      type: Boolean,
      default: null
    },
    /** 編集不可状態 */
    disabledSubmit: {
      type: Boolean,
      default: false
    },
    /** 編集する文字 v-modelで渡す */
    value: String,
    /** 表示する文字の大きさデフォルトはtitle */
    size: {
      type: String,
      default: 'title',
      require: false
    },
    /** 変更する対象の指定 */
    term: {
      type: String,
      default: '',
      require: false
    },
    /** テキストエリアの編集領域に変更 */
    isTextArea: {
      type: Boolean,
      default: false,
      require: false
    },
    /** 名前のバリデーションを実行するかどうか */
    isNameValidation: {
      type: Boolean,
      default: false,
      require: false
    },
    /** 名前のバリデーション実行時のサジェスト */
    nameValidationSuggests: Array
  },
  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
        this.$emit('change', newVal)
        this.inputEditBox(newVal)
      }
    }
  },
  methods: {
    outerClick(target, eventType, callback) {
      if (!this._eventRemovers) {
        this._eventRemovers = []
      }
      target.addEventListener(eventType, callback)
      this._eventRemovers.push({
        remove() {
          target.removeEventListener(eventType, callback)
        }
      })
    },
    inputEditBox: function (val) {
      const box = { [this.term]: val }
      this.$emit('input-edit-box', box)
    },
    selectSuggest(e) {
      this.inputValue = e
    },
    onBlur() {
      if (!this.error) {
        this.$emit('finish-edit')
      }
    }
  },
  mounted() {
    this.outerClick(
      window,
      'click',
      function (e) {
        if (!this.$el.contains(e.target) && !this.error) {
          this.$emit('finish-edit')
        }
      }.bind(this)
    )
  },
  destroyed() {
    if (this._eventRemovers) {
      this._eventRemovers.forEach(function (eventRemover) {
        eventRemover.remove()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.top-edit-area {
  display: flex;
  max-width: adjustVW(972 - 24 - 24);
  &-button {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    min-width: adjustVW(168);
    margin-left: adjustVW(32);
    transition: opacity $transition-base;
    &:hover {
      opacity: 0.5;
    }
    &-disabled {
      cursor: not-allowed;
      &:hover {
        opacity: 1;
      }
    }
  }
}

.top-edit-box {
  position: relative;
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: adjustVW(48); // title横のアイコンの高さがデフォ
  font-size: 3.2rem;
  font-weight: bold;
  &::before {
    content: '';
    position: absolute;
    top: adjustVH(1);
    left: -$space-sub;
    width: calc(100% + #{$space-sub * 2});
    height: 100%;
    background: $background-decoration;
    border-radius: adjustVW(8);
    box-shadow: $border-radius-emphasis inset;
    z-index: -1;
    animation: fade ease-in-out $transition-base;
  }

  &-sub-title {
    height: auto;
    line-height: 1;
    font-size: 2.8rem;
    &::before {
      top: calc(#{-$space-text / 2} + #{adjustVH(1)});
      height: calc(100% + #{$space-text});
    }
  }

  &-description {
    height: adjustVH(80);
    font-size: $text-base;
    > .top-edit-box-input {
      overflow-y: auto;
      max-width: 100%;
      margin-top: $space-base;
      margin-bottom: $space-base;
      line-height: 1.4;
      font-weight: 400;
      @include scrollbar;
      &-dummy {
        max-width: adjustVW(972 - 24 - 24);
      }
    }
  }

  &-input {
    position: relative;
    display: flex;
    max-width: adjustVW(972 - 24 - 24 - 200);
    height: 100%;
    padding: 0;
    margin-top: adjustVH(-2);
    &-inner {
      width: 100%;
      padding-right: adjustVW(24 + 4 + 8);
    }

    &-dummy {
      overflow: hidden;
      width: fit-content;
      max-width: adjustVW(972 - 24 - 24 - 200);
      height: 0;
      visibility: hidden;
    }
  }

  &-accept {
    &::before {
      border-radius: adjustVW(8);
      box-shadow: $border-radius-emphasis inset;
    }

    &-icon {
      position: absolute;
      top: calc(50% + #{adjustVH(2)});
      right: $space-min;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      transform: translateY(-50%);
    }
  }

  &-error {
    &::before {
      border-radius: adjustVW(8);
      box-shadow: $border-radius-caution inset;
    }

    &-icon {
      position: absolute;
      top: 0;
      right: $space-base;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
