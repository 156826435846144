<template>
  <transition name="toggle-fade" mode="out-in">
    <slot />
  </transition>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.toggle-fade-enter-active,
.toggle-fade-leave-active {
  transition: opacity $transition-base;
}
.toggle-fade-enter,
.toggle-fade-leave-to {
  opacity: 0;
  will-change: opacity;
}
</style>
