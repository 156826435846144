<template>
  <div>
    <div class="c-textblock-title">
      {{ title }}
    </div>
    <div class="c-textblock-wrap">
      <text-with-icon
        :text="text"
        size="medium"
        :pos="pos"
        :iconName="iconName"
        :color="color"
        :isShowAll="isShowAll"
        :isBold="isBold"
      >
        <slot />
      </text-with-icon>
    </div>
  </div>
</template>

<script>
import textWithIcon from '@/components/molecules/text-with-icon.vue'

export default {
  components: {
    textWithIcon
  },
  props: {
    /** 表示するテキスト */
    text: [String, Number],
    /** 表示するテキストにつくアイコン */
    iconName: {
      type: String,
      require: false
    },
    /** アイコンの表示位置 ['left', 'right'] */
    pos: {
      type: String,
      require: false,
      default: 'left',
      validator: function (value) {
        return ['left', 'right'].indexOf(value) !== -1
      }
    },
    /** テキストの上に表示するタイトル */
    title: String,
    /** 文字の色やデコレーション ['default', 'gray', 'emphasis', 'link', 'link-default', 'link-caution', 'function', 'caution', 'accept', 'disabled', 'off', 'text-button'] */
    color: String,
    /** 折り返して全文を表示 */
    isShowAll: Boolean,
    /** 太字にする */
    isBold: Boolean
  }
}
</script>

<style scoped lang="scss">
.c-textblock {
  &-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &-left {
      justify-content: flex-start;
    }
  }
  &-title {
    color: $text-sub;
    font-size: $text-base;
    font-weight: 400;
    @include text-crop;
  }
}
</style>
