<template>
  <div class="c-item-wrap">
    <a
      v-if="item && item.link !== -1"
      class="c-item-block"
      :href="item.link"
      :target="target"
      @click="$emit('click-link', item)"
    >
      <texts
        v-if="item.content"
        class="c-item-block-content"
        :text="item.content"
        size="small"
      />
      <description
        v-if="item.description"
        class="c-item-block-desc"
        :text="item.description"
        isGray
      />
      <slot v-else />
    </a>
    <div v-else class="c-item-block">
      <texts
        v-if="item && item.content"
        class="c-item-block-content"
        :text="item.content"
        size="small"
      />
      <description
        v-if="item && item.description"
        class="c-item-block-desc"
        :text="item.description"
        isGray
      />
      <slot v-else />
    </div>
  </div>
</template>

<script>
import description from './description.vue'

export default {
  components: {
    description
  },
  props: {
    /** content: 表示するテキスト description: 小さく表示する説明文 link: 外部サイトのURL */
    item: Object,
    /** _blankを指定することで別タブで開ける */
    target: {
      default: '',
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.c-item {
  &-block {
    display: block;
    padding: $space-sub $space-small;
    border: $border-sub;
    border-radius: adjustVW(8);
    &-desc {
      margin-top: $space-text;
    }
  }
  &-wrap {
    margin: 0 0 $space-small;
    &:last-child {
      margin: 0;
    }
  }
}
</style>
