var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"task-list"},_vm._l((_vm.sortedItems),function(item){return _c('div',{key:item.jobId,staticClass:"task-item",attrs:{"row":3}},[_c('div',{staticClass:"task-item-inner"},[_c('div',{staticClass:"task-item-content"},[_c('text-with-title',{staticClass:"task-item-content-project",attrs:{"title":_vm.$t('common.task.projectTitle'),"text":item.projectName,"isBold":""}}),_c('text-with-title',{staticClass:"task-item-content-time",attrs:{"title":_vm.$t('common.task.startedAt')}},[_c('a-time',{attrs:{"value":item.startedAt,"withTime":""}})],1)],1),_c('div',{staticClass:"task-item-button-wrap"},[(!_vm.isOptimization && !_vm.isFailedOptimization)?_c('button-main',{staticClass:"task-item-button",attrs:{"size":"thin","text":_vm.$t('common.showDetail'),"type":"green","fontSize":"15"},on:{"click":function($event){return _vm.linkTo(item)}}}):_vm._e(),(_vm.isFailedOptimization)?_c('button-main',{staticClass:"task-item-button",attrs:{"size":"thin","text":_vm.$t('common.button.reStart'),"type":"emphasis","fontSize":"15"},on:{"click":function($event){return _vm.linkTo(item)}}}):_vm._e(),(
            _vm.showStop &&
              !_vm.isOptimization &&
              !_vm.isFailedOptimization &&
              _vm.type === 'training'
          )?_c('button-main',{staticClass:"task-item-button",attrs:{"size":"thin","text":_vm.$t('training.popup.progress.stopButton', { type: _vm.typeText }),"type":_vm.showedStopTaskPopup ? 'disabled' : 'caution',"fontSize":"15"},on:{"click":function($event){_vm.showedStopTaskPopup ? '' : _vm.stop(item)}}}):_vm._e()],1),(_vm.isOptimization || _vm.isFailedOptimization)?_c('div',{staticClass:"task-item-optimization",class:{
          'task-item-optimization-failed': _vm.isFailedOptimization
        }},[_c('texts',{staticClass:"task-item-optimization-text",attrs:{"text":_vm.optimizationText,"size":"min","color":"gray"}})],1):_vm._e()])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }