<template>
  <!-- help-area -->
  <div class="c-hover-menu-wrap">
    <div
      class="c-hover-menu-body"
      :class="{ active: showHelp || showTraining || showInference }"
    >
      <div class="c-hover-menu-icon-list">
        <template v-if="showTask">
          <button
            v-tooltip.bottom="
              $t('common.task.iconButtonTips', {
                type: $t('common.task.training')
              })
            "
            class="c-hover-menu-icon-item"
            @click="hoverTraining()"
          >
            <icons
              class="c-hover-menu-icon-self"
              :class="{ active: showTraining }"
              iconName="trainedAi"
              size="large"
              color="green"
            />
          </button>
          <button
            v-tooltip.bottom="
              $t('common.task.iconButtonTips', {
                type: $t('common.task.inference')
              })
            "
            class="c-hover-menu-icon-item"
            @click="hoverInference()"
          >
            <icons
              class="c-hover-menu-icon-self"
              :class="{ active: showInference }"
              iconName="inference"
              size="large"
              color="green"
            />
          </button>
        </template>
        <button
          v-tooltip.bottom="helpTips"
          class="c-hover-menu-icon-item"
          @click="hoverMenuClick()"
          @mouseover="onHoverMenu"
          @mouseleave="onLeaveMenu"
        >
          <icons
            class="c-hover-menu-icon-self"
            :class="{ active: showHelp }"
            iconName="help"
            size="large"
            color="green"
          />
        </button>
      </div>
      <div class="c-hover-menu-main" :class="{ active: showHelp }">
        <div v-show="showHelp">
          <help-contents :category="category" />
        </div>
      </div>
      <template v-if="showTask">
        <!-- 学習用 -->
        <div class="c-hover-menu-main" :class="{ active: showTraining }">
          <div v-show="showTraining" class="c-hover-menu-inner">
            <tasks
              v-if="showTraining"
              type="training"
              :pending="pending"
              :running="running"
              :finished="finished"
              :waitGetTasks="waitGetTasks"
              :deletingTask="popup.deletingTask"
              :showedPopup="popup.showPopup"
              @stop-task="$emit('stop-task', $event)"
              @link-to-optimization-failed="
                $emit('link-to-optimization-failed', $event)
              "
              @delete-optimization-failed="
                $emit('delete-optimization-failed', $event)
              "
            />
          </div>
        </div>
        <!-- 推論用 -->
        <div class="c-hover-menu-main" :class="{ active: showInference }">
          <div v-show="showInference" class="c-hover-menu-inner">
            <tasks
              v-if="showInference"
              type="inference"
              :pending="inferencePending"
              :running="inferenceRunning"
              :finished="inferenceFinished"
              :waitGetTasks="waitGetInferenceTasks"
              @stop-task="$emit('stop-task', $event)"
            />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import icons from '@/components/atoms/icon.vue'
import helpContents from './help-contents.vue'
import tasks from '@/components/organisms/tasks-window/tasks.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'HelpMenu',
  components: {
    icons,
    helpContents,
    tasks
  },
  data() {
    return {
      showHelp: false,
      showTraining: false,
      showInference: false,
      hoverHelp: false,
      searchTarget: null,
      waitGetTasks: false,
      waitGetInferenceTasks: false
    }
  },
  props: {
    popup: Object,
    isHelpTipsOpen: Boolean,
    showTask: Boolean
  },
  computed: {
    ...mapGetters('tasks', [
      'pending',
      'running',
      'finished',
      'inferencePending',
      'inferenceRunning',
      'inferenceFinished'
    ]),
    category() {
      return this.$route.name
    },
    // sessionの最初のプロジェクト一覧への遷移時にのみ表示されるツールチップ
    helpTips() {
      const tips = {
        content: this.$t('help.menu.firstInPop'),
        show:
          (this.isHelpTipsOpen && !this.hoverHelp && !this.showHelp) ||
          (!this.showHelp && this.hoverHelp),
        trigger: 'manual'
      }
      return tips
    }
  },
  methods: {
    ...mapActions('tasks', ['getTasks', 'getInferenceTasks']),
    hoverMenuClick() {
      this.showHelp = !this.showHelp
      if (this.showHelp) {
        this.showTraining = false
        this.showInference = false
        this.$gtmDataLayer.sendEventCategory(
          'マニュアル',
          'マニュアルを開いたページ',
          this.$t(`help.categories.${this.category}`)
        )
      }
      this.hoverHelp = false
    },
    async hoverTraining() {
      this.showTraining = !this.showTraining
      if (this.showTraining) {
        this.showHelp = false
        this.showInference = false
        await this.getTrainingTasksAsync()
      }
    },
    async hoverInference() {
      this.showInference = !this.showInference
      if (this.showInference) {
        this.showHelp = false
        this.showTraining = false
        await this.getInferenceTasksAsync()
      }
    },
    async getTrainingTasksAsync() {
      try {
        this.waitGetTasks = true
        await this.getTasks()
      } finally {
        this.waitGetTasks = false
      }
    },
    async getInferenceTasksAsync() {
      try {
        this.waitGetInferenceTasks = true
        await this.getInferenceTasks()
      } finally {
        this.waitGetInferenceTasks = false
      }
    },
    outerClick(target, eventType, callback) {
      if (!this._eventRemovers) {
        this._eventRemovers = []
      }
      target.addEventListener(eventType, callback)
      this._eventRemovers.push({
        remove() {
          target.removeEventListener(eventType, callback)
        }
      })
    },
    onHoverMenu() {
      this.hoverHelp = true
      if (this.isHelpTipsOpen) {
        this.$emit('on-hover-help')
      }
    },
    onLeaveMenu() {
      this.hoverHelp = false
    }
  },
  async mounted() {
    this.outerClick(
      window,
      'click',
      function (e) {
        if (!this.$el.contains(e.target)) {
          this.showHelp = false
          this.showTraining = false
          this.showInference = false
        }
      }.bind(this)
    )
    if (this.showTask) {
      await this.getTrainingTasksAsync()
      await this.getInferenceTasksAsync()
    }
  },
  destroyed() {
    if (this._eventRemovers) {
      this._eventRemovers.forEach(function (eventRemover) {
        eventRemover.remove()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.c-hover-menu {
  &-wrap {
    position: fixed;
    top: adjustVH($headerTabHeight);
    right: 0;
    background: $background;
    z-index: $z-help;
  }
  &-body {
    width: 0;
    height: 100vh - adjustVH($headerTabHeight);
    transition: $transition-base;
    &.active {
      width: adjustVW(420);
    }
  }
  &-main {
    position: absolute;
    right: adjustVW(-420);
    width: adjustVW(420);
    height: 100vh;
    padding: $space-medium 0 0;
    box-shadow: $box-shadow-main;
    transition: $transition-base;
    &.active {
      right: 0;
    }
  }
  &-inner {
    height: 100%;
  }
  &-icon-list {
    position: absolute;
    top: adjustVW(16);
    right: adjustVW(16);
    display: flex;
  }
  &-icon-item {
    position: relative;
    margin: 0 $space-medium 0 0;
    z-index: $z-help-icon;
    &:last-child {
      margin: 0;
    }
  }
  &-icon-self {
    width: adjustVW(52);
    height: adjustVW(52);
    background: $background;
    border-radius: 9in;
    cursor: pointer;
    box-shadow: 0 0 adjustVW(12) adjustVW(2) rgba(4, 145, 133, 0.05);
    &.active {
      background: $green-lite;
      box-shadow: inherit;
    }
  }
}
</style>
