<template>
  <transition-toggle-fade v-if="!disabled">
    <button
      v-if="!editLoading"
      class="top-title-edit"
      @click.stop="$emit('click-edit')"
    >
      <icons
        class="top-title-edit-icon"
        iconName="edit"
        size="min"
        color="emphasis"
      />
    </button>
    <div v-else class="top-title-edit-loading">
      <loading-icon size="small" />
      <texts :text="$t('common.waiting.edit')" color="gray" size="small" />
    </div>
  </transition-toggle-fade>
  <div
    v-else
    v-tooltip="disabledEditTips"
    class="top-title-edit top-title-edit-disabled"
    @click.stop
  >
    <icons
      class="top-title-edit-icon"
      iconName="edit"
      size="min"
      color="gray"
    />
  </div>
</template>

<script>
import icons from '@/components/atoms/icon'
import loadingIcon from '@/components/atoms/loading-icon'
import transitionToggleFade from '@/components/molecules/transition-toggle-fade'

export default {
  components: {
    icons,
    loadingIcon,
    transitionToggleFade
  },
  props: {
    /** 使用不可状態 */
    disabled: Boolean,
    /** 編集後の更新まち */
    editLoading: Boolean
  },
  computed: {
    disabledEditTips() {
      return {
        content: this.$t('common.disabled.organization.otherAccountItem'),
        trigger: 'hover',
        delay: { show: 200, hide: 200 }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.top-title-edit {
  display: flex;
  align-items: center;
  justify-content: center;
  width: adjustVW(28);
  height: adjustVW(28);
  background: $background;
  border-radius: 9in;
  box-shadow: $box-shadow-hover;
  transition: opacity $transition-base;
  &:hover {
    opacity: 0.5;
  }
  &-disabled {
    cursor: not-allowed;
    &:hover {
      opacity: 1;
    }
  }
  &-loading {
    display: flex;
    align-self: center;
    grid-column-gap: $space-sub;
    margin-left: $space-small;
  }
}
</style>
