var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input-check-inner",class:{
    'input-check-small': _vm.isSmall
  }},[_c('label',{class:{
      'input-check-disabled': _vm.isDisabled
    }},[_c('input',{staticClass:"input-check-box",attrs:{"type":"checkbox","disabled":_vm.isDisabled},domProps:{"value":_vm.text,"checked":_vm.isChecked},on:{"change":function($event){return _vm.updateValue($event)}}}),_c('div',{staticClass:"input-check-icon",class:{
        'input-check-icon-disabled': _vm.isDisabled,
        'input-check-icon-caution': _vm.caution
      }},[_c('icons',{staticClass:"input-check-icon-check",attrs:{"iconName":_vm.isShowMinusIcon ? 'minus' : 'check',"size":_vm.isSmall ? 12 : 'min',"color":_vm.isDisabled ? 'gray' : _vm.caution ? 'caution' : 'emphasis'}})],1),_c('div',{staticClass:"input-check-text",class:{
        'input-check-text-large': _vm.isLarge
      }},[_c('texts',{attrs:{"text":_vm.text,"color":_vm.caution ? 'caution' : 'default',"size":_vm.isSmall ? 'min' : _vm.isLarge ? 'default' : 'small'}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }