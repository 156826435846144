<template>
  <div
    class="c-help-contents"
    :class="{ 'c-help-contents-with-community': checkShowCommunity }"
  >
    <div class="c-help-wrap">
      <div class="c-help-title">
        <texts :text="$t('help.menu.title')" />
      </div>
      <div class="c-help-search">
        <input-block
          :value="inputValue"
          :placeholder="$t('help.menu.inputQuestion')"
          isSearch
          @input="updateInput"
          @change="updateChange"
        />
      </div>
      <div v-if="!inputValue" class="c-help-search-result-title">
        <texts
          class="c-help-search-result-title-inner"
          :text="
            $t('help.menu.popularQuestion', { categoryName: categoryName })
          "
          size="small"
        />
      </div>
      <transition-toggle-contents>
        <div v-if="searchResult" class="c-help-search-result-list">
          <template v-if="searchResult.length > 0">
            <item-list
              :items="searchResult"
              target="_blank"
              @click-link="clickLink"
            />
            <button class="c-help-search-result-all" @click="goManual">
              <texts
                :text="$t('help.showAllItem')"
                size="small"
                color="link-default"
              />
            </button>
          </template>
          <item v-else>
            <div class="c-help-search-result-none">
              <texts
                class="c-help-search-result-none-text"
                :text="$t('help.noItem.text', { searchText: cropText })"
                size="small"
              />
              <button-main
                class="c-help-search-result-none-button"
                :text="$t('help.noItem.button')"
                type="sub"
                @click="goManual"
              />
            </div>
          </item>
        </div>
        <loading-icon v-else />
      </transition-toggle-contents>
    </div>
    <div class="c-contact-wrap">
      <div class="c-contact-title">
        <texts :text="$t('help.menu.contactTitle')" />
      </div>
      <div class="c-contact-description">
        <description line="inheirit" :text="$t('help.menu.contact')" />
      </div>
      <div class="c-contact-button">
        <button-main :text="$t('help.menu.contactTitle')" @click="goContact" />
      </div>
      <div v-if="checkShowCommunity" class="c-community-link">
        <texts
          class="c-community-text"
          :text="$t('common.communityLink.contactLink')"
          size="small"
          isShowAll
        />
        <button-main
          class="c-community-button"
          :text="$t('common.communityLink.buttonLink')"
          type="sub"
          @click="goCommunity"
        />
      </div>
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text.vue'
import inputBlock from '@/components/molecules/input-box.vue'
import item from '@/components/atoms/item.vue'
import itemList from '@/components/molecules/item-list.vue'
import loadingIcon from '@/components/atoms/loading-icon.vue'
import buttonMain from '@/components/atoms/button-main.vue'
import description from '@/components/atoms/description.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import Fuse from 'fuse.js'
import { mapGetters } from 'vuex'

// propsのcategoryをWPのスラッグ名に変換する辞書
const CATEGORY2NAME = {
  projectList: 'project-list',
  projectDetail: 'project-dashboard',
  projectTemplate: 'project-create',
  projectTemplateList: 'project-create',
  projectTemplateDetail: 'project-create',
  selectMode: 'project-create',
  // 学習関連
  datasetSetting: 'train-dataset-setting',
  selectTargetColumn: 'train-select-target-column',
  trainPreprocessing: 'train-preprocessing',
  trainRecipeList: 'train-recipe',
  trainRecipeDetail: 'train-recipe',
  trainRecipeEdit: 'train-recipe',
  training: 'train-training',
  // 学習済みAI
  trainedAiProjectList: 'project-trained-ai',
  trainedAiProjectDetail: 'project-trained-ai',
  trainedAiList: 'project-trained-ai',
  trainedAiDetail: 'project-trained-ai',
  // 推論
  inferenceProject: 'project-inference',
  inferenceProjectTask: 'project-inference',
  inference: 'project-inference',
  // サービス
  serviceProjectList: 'project-service',
  serviceProjectDetail: 'project-service',
  serviceProjectDetailEdit: 'project-service',
  serviceList: 'project-service',
  serviceDetail: 'project-service',
  serviceDetailEdit: 'project-service',
  // データセット
  datasetProjectList: 'project-dataset',
  datasetProjectDetail: 'project-dataset',
  datasetList: 'project-dataset',
  datasetDetail: 'project-dataset',
  // 前処理
  preprocessingProjectList: 'project-preprocessing',
  preprocessingProjectDetail: 'project-preprocessing',
  preprocessingProjectApply: 'project-preprocessing',
  preprocessingProjectApplyNonePreproc: 'project-preprocessing',
  preprocessingList: 'project-preprocessing',
  preprocessingDetail: 'project-preprocessing',
  preprocessingApply: 'project-preprocessing',
  preprocessingApplyNonePreproc: 'project-preprocessing',
  // レシピ
  recipeProjectList: 'project-recipe',
  recipeProjectDetail: 'project-recipe',
  recipeProjectEdit: 'project-recipe',
  recipeList: 'project-recipe',
  recipeDetail: 'project-recipe',
  recipeEdit: 'project-recipe',
  // 各種設定
  myAccountInfo: 'account-info',
  organization: 'organization',
  changePlan: 'check-plan'
}
export default {
  components: {
    texts,
    inputBlock,
    item,
    itemList,
    loadingIcon,
    buttonMain,
    description,
    transitionToggleContents
  },
  data() {
    return {
      inputValue: '',
      fuseOptions: {
        includeScore: true,
        shouldSort: true,
        threshold: 0.2,
        keys: [
          {
            name: 'content',
            weight: 0.8
          },
          {
            name: 'description',
            weight: 0.2
          }
        ],
        distance: 240
      }
    }
  },
  props: {
    category: String
  },
  computed: {
    ...mapGetters('helps', ['categoryObject', 'reusltObject', 'allArray']),
    ...mapGetters('auth', ['accountInfo']),
    categoryName() {
      return this.$t(`help.categories.${this.category}`)
    },
    fuse() {
      return new Fuse(this.allArray, this.fuseOptions)
    },
    searchResult() {
      if (this.inputValue) {
        return Object.values(this.fuse.search(this.inputValue)).map(
          (x) => x.item
        )
      } else {
        if (this.categoryObject[CATEGORY2NAME[this.category]]?.name) {
          return this.reusltObject[
            this.categoryObject[CATEGORY2NAME[this.category]].name
          ]
        } else {
          return []
        }
      }
    },
    checkShowCommunity() {
      if (
        this.accountInfo.plan === 'basic' ||
        this.accountInfo.plan === 'education_1'
      ) {
        return true
      } else {
        return false
      }
    },
    noItem() {
      return {
        item: {
          link: this.$urls.manual
        },
        target: '_blank'
      }
    },
    cropText() {
      let targetText = this.inputValue
      if (targetText.length > 16) {
        targetText = targetText.substr(0, 15) + '...'
      }
      return targetText
    }
  },
  methods: {
    goContact() {
      this.$gtmDataLayer.sendEventCategory(
        'マニュアル',
        '問い合わせボタンをクリック',
        '問い合わせボタンをクリック'
      )
      window.open(this.$urls.contactLink, '_blank')
    },
    goCommunity() {
      window.open(this.$urls.community, '_blank')
    },
    goManual() {
      this.$gtmDataLayer.sendEventCategory(
        'マニュアル',
        'マニュアルで探すボタンをクリック時に入力されているテキスト',
        this.inputValue
      )
      window.open(this.$urls.manual, '_blank')
    },
    clickLink(item) {
      this.$gtmDataLayer.sendEventCategory(
        'マニュアル',
        '遷移したマニュアル',
        item.content
      )
    },
    updateInput(e) {
      this.inputValue = e
    },
    updateChange(e) {
      this.inputValue = e
      this.$gtmDataLayer.sendEventCategory(
        'マニュアル',
        '検索欄に入力したテキスト',
        e
      )
      this.$nextTick(() => {
        if (this.searchResult.length === 0) {
          this.$gtmDataLayer.sendEventCategory(
            'マニュアル',
            '検索したが見つからなかったテキスト',
            this.inputValue
          )
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.c-help {
  &-wrap {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: calc(
      100vh - #{adjustVH($headerTabHeight)} - #{adjustVW(24)} - #{$space-small *
        2} - #{$space-medium} - #{adjustVH(256)}
    );
    padding: 0 $space-medium $space-medium;
  }
  &-contents {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: adjustVW(24) + $space-small * 2 0 0;
    &-with-community {
      .c-help-wrap {
        height: calc(
          100vh - #{adjustVH($headerTabHeight)} - #{adjustVW(24)} - #{$space-small *
            2} - #{$space-medium} - #{adjustVH(440)}
        );
      }
      .c-contact-wrap {
        height: adjustVH(440);
      }
    }
  }
  &-title {
    margin: 0 0 $space-small;
  }
  &-search {
    margin: 0 0 $space-medium;
    &-result-title {
      margin: 0 0 $space-small;
      &-inner {
        white-space: pre-line;
      }
    }
    &-result-list {
      overflow-x: hidden;
      overflow-y: scroll;
      padding-right: $space-base;
      @include scrollbar;
    }
    &-result-none {
      padding: adjustVW(6) 0;
      &-text {
        margin-bottom: $space-sub;
        word-break: break-all;
        white-space: pre-line;
      }
      &-button {
        width: 100%;
      }
    }
    &-result-all {
      display: block;
      width: fit-content;
      margin: adjustVW(20) auto 0;
    }
  }
}
.c-contact {
  &-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: adjustVH(256);
    padding: $space-medium;
    border-top: $border-main;
  }
  &-title {
    margin: 0 0 $space-base;
  }
  &-description {
    margin: 0 0 $space-small;
  }
  &-button {
    width: 100%;
    > button {
      width: 100%;
    }
  }
}
.c-community {
  &-link {
    padding: $space-small;
    margin-top: $space-large;
    background: $green-lite;
    border-radius: adjustVW(8);
  }
  &-text {
    margin-bottom: $space-sub;
    line-height: $line-height-base;
    white-space: pre-line;
  }
  &-button {
    width: 100%;
  }
}
</style>
