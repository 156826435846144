<template>
  <svg
    :is="componentName"
    xmlns="http://www.w3.org/2000/svg"
    :aria-labelledby="componentName"
    role="presentation"
  />
</template>

<script>
import pageNotFoundImage from '@/assets/images/page-not-found.svg'
import askSupportImage from '@/assets/images/ask-support.svg'
import askSupportMinImage from '@/assets/images/ask-support-min.svg'
import congratulationImage from '@/assets/images/congratulation.svg'
import createNewItemImage from '@/assets/images/create-new-item.svg'
import noItemImage from '@/assets/images/no-item.svg'
import notFoundImage from '@/assets/images/not-found.svg'
import nowDevelopingImage from '@/assets/images/now-developing.svg'
import showBlockImage from '@/assets/images/show-block.svg'
import showDatasetImage from '@/assets/images/show-dataset.svg'
import showGraphImage from '@/assets/images/show-graph.svg'
import accuracyImage from '@/assets/images/metrics-accuracy.svg'
import precisionImage from '@/assets/images/metrics-precision.svg'
import recallImage from '@/assets/images/metrics-recall.svg'
import averageAccuracyImage from '@/assets/images/metrics-average-accuracy.svg'
import optimizationImage from '@/assets/images/optimization.svg'

export default {
  name: 'images',
  components: {
    pageNotFoundImage, // 404
    askSupportImage, // サポートに相談
    askSupportMinImage, // サポートに相談の小さいサイズ
    congratulationImage, // 作成完了
    createNewItemImage, // 新規作成しましょう
    noItemImage, // アイテムが何もない
    notFoundImage, // 検索結果なし
    nowDevelopingImage, // 開発中
    showBlockImage, // ブロックを押して詳細を表示
    showDatasetImage, // リストを押すとデータセットが表示されます
    showGraphImage, // グラフが表示されます
    accuracyImage,
    precisionImage,
    recallImage,
    averageAccuracyImage,
    optimizationImage
  },
  props: {
    /** 表示する画像名 ['pageNotFound', 'askSupport', 'congratulation', 'createNewItem', 'noItem', 'notFound', 'nowDeveloping', 'showBlock', 'showDataset', 'showGraph', 'accuracyImage', 'precisionImage', recallImage, averageAccuracyImage] */
    imageName: String
  },
  computed: {
    componentName() {
      if (!this.imageName) return null
      return this.imageName + 'Image'
    }
  }
}
</script>
