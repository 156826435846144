<!-- beforeRouteLeaveで検知できないブラウザバックなどが起きたときに、離脱しますか？ポップアップを表示するためのコンポーネント かならずViewsの階層で呼んであげてください -->
<template>
  <popup-array
    class="popup-array"
    :popupDefs="popupDefs"
    :showPopup="popup"
    @close-modal="preventLeave()"
  >
    <template #preventLeave>
      <div class="prevent-text-block">
        <texts
          v-if="cautionTexts"
          class="prevent-text-inner"
          :text="cautionTexts"
          isShowAll
          size="small"
        />
        <template v-else>
          <slot>
            <!--
              `使用時には必ず以下のコードをviewsのコンポーネントに書いてください this.$refs.preventLeave.preventの箇所はv-modelでpreventの状態を渡しているなら、その渡している値を入れても構いません
              async beforeRouteLeave (to, from, next) {
                if (this.$refs.preventLeave.prevent) {
                  const result = await this.$refs.preventLeave.$confirm()
                  if (result) {
                    next()
                  } else {
                    next(false)
                  }
                } else {
                  next()
                }
              },`

              また、template で呼び出すときはrefをつけて、
              preventの状態を変更したければv-modelで渡してください
              <prevent-leave
                ref="preventLeave"
                v-model='isPrevent'
              />
            }} -->
          </slot>
        </template>
      </div>
      <div class="prevent-button-list">
        <button-main
          v-if="displaySaveButton"
          class="prevent-button-item"
          :text="saveButtonDefs"
          :type="saveButtonColor"
          @click="preventLeave(), $emit('save-event')"
        />
        <button-main
          v-if="displayCloseButton"
          class="prevent-button-item"
          :text="closeButtonDefs"
          :type="closeButtonColor"
          @click="preventLeave()"
        />
        <button-main
          class="prevent-button-item"
          :text="leaveButtonDefs"
          :type="leaveButtonColor"
          @click="leave()"
        />
      </div>
    </template>
  </popup-array>
</template>

<script>
import { popupArray } from '@/components/molecules/popup'
import ButtonMain from '@/components/atoms/button-main.vue'
import texts from '@/components/atoms/text'

export default {
  components: {
    popupArray,
    ButtonMain,
    texts
  },
  data() {
    return {
      popup: [],
      prevent: this.value,
      okResolve: () => {
        return null
      },
      cancelResolve: () => {
        return null
      }
    }
  },
  props: {
    /** ポップアップに表示するテキスト */
    title: {
      type: String,
      default: '',
      require: false
    },
    /** まだこの画面に残る場合のボタンに表示するテキスト */
    saveButton: {
      type: String,
      default: '',
      require: false
    },
    /** まだこの画面に残る場合のボタンの色 */
    saveButtonColor: {
      type: String,
      default: 'emphasis',
      require: false
    },
    /** この画面から離れる場合のボタンのテキスト */
    leaveButton: {
      type: String,
      default: '',
      require: false
    },
    /** この画面から離れる場合のボタンの色 */
    leaveButtonColor: {
      type: String,
      default: 'caution',
      require: false
    },
    /** 離れる前の注意文言（Slotでもいれれる） */
    cautionTexts: {
      type: String,
      default: '',
      require: false
    },
    value: {
      // preventしているかどうかデフォルトはtrue
      type: Boolean,
      default: true,
      require: false
    },
    /** saveButtonを表示するかどうかを表す（true: 表示、false: 非表示） */
    displaySaveButton: {
      type: Boolean,
      default: true,
      require: false
    },
    /** このポップアップを閉じるボタンのテキスト */
    closeButton: {
      type: String,
      default: '',
      require: false
    },
    /** このポップアップを閉じる場合のボタンの色 */
    closeButtonColor: {
      type: String,
      default: 'emphasis',
      require: false
    },
    /** closeButtonを表示するかどうかを表す（true: 表示、false: 非表示） */
    displayCloseButton: {
      type: Boolean,
      default: false,
      require: false
    }
  },
  computed: {
    popupDefs() {
      return {
        preventLeave: {
          title: this.titleDefs
        }
      }
    },
    titleDefs() {
      return this.title
        ? this.title
        : this.$t('common.preventLeaveDefault.title')
    },
    saveButtonDefs() {
      return this.saveButton
        ? this.saveButton
        : this.$t('common.preventLeaveDefault.save')
    },
    leaveButtonDefs() {
      return this.leaveButton
        ? this.leaveButton
        : this.$t('common.preventLeaveDefault.leave')
    },
    closeButtonDefs() {
      return this.closeButton
        ? this.closeButton
        : this.$t('common.preventLeaveDefault.close')
    }
  },
  methods: {
    // window.confirmと同様の機能性のメソッド
    // https://qiita.com/arene-calix/items/fdcf29d47279b5330f1e
    async $confirm() {
      return new Promise((resolve) => {
        this.okResolve = () => resolve(true)
        this.cancelResolve = () => resolve(false)
        this.showPopup()
      })
    },
    beforeunloadHandler(e) {
      // リロードしたら各ブラウザのポップアップが表示
      if (this.prevent) {
        e.preventDefault()
        e.returnValue = this.titleDefs
      }
    },
    closePopup() {
      this.popup = this.popup.filter((n) => n !== 'preventLeave')
    },
    showPopup() {
      if (!this.popup.find((n) => n === 'preventLeave')) {
        this.popup.push('preventLeave')
      }
    },
    preventLeave() {
      this.cancelResolve()
      this.closePopup()
    },
    leave() {
      this.okResolve()
      this.closePopup()
    }
  },
  watch: {
    value(newVal) {
      this.prevent = newVal
    },
    prevent(newVal) {
      this.$emit('input', newVal)
    }
  },
  created() {
    window.addEventListener('beforeunload', this.beforeunloadHandler, false)
  },
  destroyed() {
    window.removeEventListener('beforeunload', this.beforeunloadHandler, false)
  }
}
</script>

<style lang="scss" scoped>
.prevent {
  &-text {
    &-block {
      min-width: adjustVW(720 - 24 - 32);
      margin: 0 0 $space-medium;
    }
    &-inner {
      white-space: pre-line;
    }
  }
  &-button {
    &-list {
      display: flex;
    }
  }
}
.popup-array {
  z-index: $z-prevent-popup;
}
</style>
