<template>
  <div>
    <item
      v-for="(item, index) in items"
      :key="index"
      :item="item"
      :target="target"
      @click-link="$emit('click-link', $event)"
    />
  </div>
</template>

<script>
import item from '@/components/atoms/item.vue'

export default {
  components: {
    item
  },
  props: {
    /** 表示するアイテムのリスト content: 表示するテキスト, description: 小さく表示する説明文, link: 外部リンク */
    items: Array,
    /** _blankを指定することで別タブで開ける */
    target: {
      default: '',
      type: String
    }
  }
}
</script>
